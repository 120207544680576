import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { NameValue } from '../models/nameValue.model';
import { Requirement } from '../models/requirement.model';
import { SaveDocumentsDTO } from '../models/saveDocumentsDTO.model';

@Injectable({
  providedIn: 'root'
})
export class TramiteSharedService {

  constructor(private http: HttpClient) {
  }

  getConstantById(id:string):Observable<NameValue> {
    return this.http.get<any>(`${environment.apiUrlBack}/api/v1/Constant/GetConstantByIdConstant/${id}`).pipe(map(resp => {
      return resp.data;
    }));
  }

  getConstantsNameValue(id:string):Observable<NameValue[]> {
    return this.http.get<any>(`${environment.apiUrlBack}/api/v1/Constant/GetConstantsByIdFather/${id}`).pipe(map(resp => {
      return resp.data;
    }));
  }

  getDocumentsRequirementsByIdSection(id : string):Observable<Requirement[]> {
    return this.http.get<any>(`${environment.apiUrlBack}/api/v1/Document/GetDocumentRequirementByIdSection/${id}`).pipe(map(resp => {
      return resp.data;
    }));
  }

  convertBase64ToPdfFile(base64: string, filename: string): File {
    try {
      base64 = `data:application/pdf;base64,${base64}`;
      const base64Arr = base64.split(',');
      const mime = base64Arr[0].match(/:(.*?);/)[1];
      const bstr = atob(base64Arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } catch (e) {

      return null;
    }
  }

  saveDocumentsRequestBLOB(formData: any): Observable<any> {
    return this.http.post(`${environment.apiFileManager}/api/v1/Storage/AddFile`, formData);
  }

  saveDocumentsRequestDB(json: SaveDocumentsDTO[]): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrlBack }/api/v1/Document/SaveDocuments`, json);
  }

  updateStateDocuments(json: any): Observable<any> {
    return this.http.put(`${environment.apiUrlBack}/api/v1/Document/UpdateDocument`, json);
  }

  getBlobDocument(json : any):Observable<any> {
    return this.http.get(`${environment.apiFileManager}/api/v1/Storage/GetBlob/${environment.containerBlob}/${json.numRadicado}/${json.nameDocument}`, {responseType: 'blob'});
  }

  generateDocument(json: any): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrlBack }/api/v1/Document/GenerateDocument`, json);
  }

  saveOficio(json: any): Observable<any> {
    return this.http.post(`${environment.apiUrlBack}/api/v1/Resolution/SaveOficio`, json);
  }

  saveResolucion(json: any): Observable<any> {
    return this.http.post(`${environment.apiUrlBack}/api/v1/Resolution/SaveResolution`, json);
  }

  validarNumeroActa(idRequest : string, numActa:string):Observable<boolean> {
    return this.http.get<any>(`${environment.apiUrlBack}/api/v1/Visit/ComparetRecordNumber/${numActa}/${idRequest}`).pipe(map(resp => {
      return resp.data;
    }));
  }


  //API SHARED
  getInfoUserByIdCodeVentanilla(idCode: any): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v2/Persona/GetInfoUserById/${idCode}`);
  }

  getTiposIdentificacion():Observable<NameValue[]> {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/TipoIdentificacion/GetTipoIdentificacion`).pipe(map(resp => {
      return resp.data.map(typeDocument => {
        return {
          name : typeDocument.descripcion,
          value : typeDocument.idTipoIdentificacion
        }
      });
    }));
  }

  getZones() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1//SubRed/GetSubRed`);
  }

  getLocalities() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1//Localidad/GetAllLocalidad`);
  }

  getLocalitiesNameValue() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Localidad/GetAllLocalidad`).pipe(map(resp => {
      return resp.data.map(localidad => {
        return {
          name : localidad.nombre,
          value : localidad.idLocalidad
        }
      });
    }));
  }

  getUpzByIdLocalidad(idLocalidad : string) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Upz/GetUpzByIdLocalidad/${idLocalidad}`);
  }

  getBarriosByUpz(idupz : string) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrioByIdUpzTramites/${idupz}`);
  }
  getUpz() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1//Upz/GetUpz`);
  }

  getBarrios() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1//Barrio/GetBarrios`);
  }

  calculateRemainingDays(initialDate, idTramite): Observable<any> {
    return this.http.get<Request>(`${ environment.TRAMITE_SHARED_URL }/v1/Holiday/CalculateRemainingDays/${ initialDate }/${ idTramite }`).pipe(map(resp => {
      return resp;
    }))
  }

  getAddressGeoreferenciador(address : string) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Direccion/GetDireccion/${ address }`);
  }
}

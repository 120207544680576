// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //apiUrlBack: 'https://app-sds-certdispmedicos-dev-eastus.azurewebsites.net',
  apiUrlBack: 'https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Certdispmedicos',
  apiFileManager: 'https://apm-aeu-sds-dev-shared.azure-api.net/filemanager',

  TRAMITE_SHARED_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api',
  TRAMITE_SECURITY_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/security/api/v2/Security',
  TRAMITE_NOTIFICATIONS_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/notifications/api/v1',


  procedureName : 'Certificación de capacidad de adecuación y/o dispensación de dispositivos médicos sobre medida, para la salud visual y ocular',
  procedureFullName : 'Certificación de capacidad de adecuación y/o dispensación de dispositivos médicos sobre medida, para la salud visual y ocular',
  idProcedureDescription : '218F75DF-2F8D-4677-A8E0-FDECB1068824',
  numProcedure : '5',
  containerBlob : 'certdispmedicos',
  idProcedureDBCommmons : "A8190C8B-11AE-458D-9428-CA106CDA9533",
  KEY_RECAPTCHA_SITE_WEB: '6Lel3eMmAAAAALkg4o93dldoWIAP50FH4KE-E3ii',

  VUDTS_URL: 'https://dev.ventanillavirtualtramites.saludcapital.gov.co',
  VUDTS_URL_LOCAL: 'localhost:4200',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

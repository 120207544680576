import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from 'rxjs';
import Swal from "sweetalert2";
import {PopupService} from "./popup.service";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  sectionNumber = new BehaviorSubject(null);
  sectionNumber$ = this.sectionNumber.asObservable();

  listRequirements = new BehaviorSubject<any[]>([]);
  listRequirements$ = this.listRequirements.asObservable();

  listDocuments = new BehaviorSubject<any[]>([]);
  listDocuments$ = this.listDocuments.asObservable();

  listRevisionDocuments = new BehaviorSubject(null);
  listRevisionDocuments$ = this.listRevisionDocuments.asObservable();

  numDocsRequired = new BehaviorSubject<number[]>([]);
  numDocsRequired$ = this.numDocsRequired.asObservable();

  numDocs = new BehaviorSubject<any[]>([]);
  numDocs$ = this.listDocuments.asObservable();

  missingDocsRequired  = new BehaviorSubject<any[]>([]);
  missingDocsRequired$ = this.missingDocsRequired.asObservable();

  constructor(private http: HttpClient,private popupAlert: PopupService) {
  }

  setListDocuments(listDocuments: any, sectionNumber:number) {
    this.listDocuments.value[sectionNumber] = listDocuments;
    this.listDocuments.next(this.listDocuments.value);
  }

  setMissingDocsRequired(sectionNumber : number){
    let missingDocsRequired = (((this.numDocs && this.numDocs.value[sectionNumber]) ? this.numDocs.value[sectionNumber]  : 0) < ((this.numDocsRequired && this.numDocsRequired.value[sectionNumber]) ? this.numDocsRequired.value[sectionNumber] : 1));
    this.missingDocsRequired.value[sectionNumber] = missingDocsRequired;
    this.missingDocsRequired.next(this.missingDocsRequired.value);
  }

  setNumDocsRequired(sectionNumber : number){
    if(this.listRequirements && this.listRequirements.value[sectionNumber] != null && this.listRequirements.value[sectionNumber].length > 0){
      let numDocsRequired = this.listRequirements.value[sectionNumber].filter(requirement => requirement.required == true).length;
      this.numDocsRequired.value[sectionNumber] = numDocsRequired;
      this.numDocsRequired.next(this.numDocsRequired.value);
    }
  }

  setNumDocs(sectionNumber : number){
    if(this.listDocuments && this.listDocuments.value[sectionNumber] != null && this.listDocuments.value[sectionNumber].length > 0){
      this.numDocs.value[sectionNumber] = this.listDocuments.value[sectionNumber].length;
      this.numDocs.next(this.numDocs.value);
    }else{
      this.numDocs.value[sectionNumber] = 0;
      this.numDocs.next(this.numDocs.value);
    }
  }


  public downloadfile(pathArchive: string): void {
    try
    {
      this.popupAlert.infoAlert('Cargando documento...', 4000);

        const oidAndNameBlob: Array<string> = pathArchive.split("/")
        const contenedor: string = environment.containerBlob;
        const oid: string = oidAndNameBlob[9]
        const nameBlob: string = oidAndNameBlob[10]

        this.http.get(`${environment.apiFileManager}/api/v1/Storage/GetBlob/${contenedor}/${oid}/${nameBlob}`, { responseType: 'blob' })
          .subscribe((blob: Blob) => {

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = data;
            link.download = `${nameBlob}.xlsx`;
            link.click();

          });


    } catch (e) {
      console.log(e)
      this.popupAlert.errorAlert(
        'Ocurrió un error al previsualizar el documento.',
        4000);
    }
  }


  /**
   * Permite visualizar un archivo en un iframe de una ventana actual
   * @param pathArchive Ruta del archivo
   */
  public viewArchiveActualWindow(pathArchive: string, fileBlob?: Blob): void {
    try
    {
      this.popupAlert.infoAlert('Cargando documento...', 4000);

      if (pathArchive == "") {
        window.open(window.URL.createObjectURL(fileBlob), '_blank');
      } else {
        const oidAndNameBlob: Array<string> = pathArchive.split("/")
        const contenedor: string = environment.containerBlob;
        const oid: string = oidAndNameBlob[9]
        const nameBlob: string = oidAndNameBlob[10]

        this.http.get(`${environment.apiFileManager}/api/v1/Storage/GetBlob/${contenedor}/${oid}/${nameBlob}`, { responseType: 'blob' })
          .subscribe((blob: Blob) => {
            document.querySelector("iframe").src = window.URL.createObjectURL(blob);
          });
      }

    } catch (e) {
      console.log(e)
      this.popupAlert.errorAlert(
        'Ocurrió un error al previsualizar el documento.',
        4000);
    }
  }

  /**
   * Permite visualizar un archivo en un pop up de sweet alert
   * @param pathArchive Ruta del archivo cuando existen en el contenedor blobStorage
   * @param fileBlob Blob a mostrar Cuando el archivo es temporal (para preeliminar)
   */
  public viewArchiveInPopUp(pathArchive: string, fileBlob?: Blob): void {
    try {
      this.popupAlert.infoAlert('Cargando documento...', 10000);

      if (pathArchive == "") {
        Swal.fire({
          title: 'Pdf',
          showCloseButton: true,
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#3366CC',
          html: `<iframe src='${window.URL.createObjectURL(fileBlob)}' width="1300" height="700" title="visualizacion-documento">`,
          width: '75%',
        });
      } else {
        const oidAndNameBlob: Array<string> = pathArchive.split("/")
        const contenedor: string = environment.containerBlob;
        const oid: string = oidAndNameBlob[9]
        const nameBlob: string = oidAndNameBlob[10]

            let url=`${environment.apiFileManager}/api/v1/Storage/GetBlob/${contenedor}/${oid}/${nameBlob}`;
            Swal.fire({
              title: 'Pdf',
              showCloseButton: true,
              confirmButtonText: 'Cerrar',
              confirmButtonColor: '#3366CC',
              html: `<iframe src='${url}' width="1300" height="700" title="visualizacion-documento">`,
              width: '75%',
            });


      }

    } catch (e) {
      console.log(e)
      this.popupAlert.errorAlert(
        'Ocurrió un error al previsualizar el documento.',
        4000);
    }
  }
  convertBase64ToPdfFile(base64: string, filename: string): File {
    try {
      base64 = `data:application/pdf;base64,${base64}`;
      const base64Arr = base64.split(',');
      const mime = base64Arr[0].match(/:(.*?);/)[1];
      const bstr = atob(base64Arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } catch (e) {

      return null;
    }
  }

}
